type TableColumn = {
  name: string;
  style?: {
    minWidth: string;
  };
  key: string;
};

export const inTakeTableHeaders: TableColumn[] = [
  { name: "First Name", style: { minWidth: "180px" }, key: "firstName" },
  { name: "Last Name", style: { minWidth: "180px" }, key: "lastName" },
  { name: "Email", style: { minWidth: "180px" }, key: "email" },
  { name: "Phone No", style: { minWidth: "180px" }, key: "phoneNo" },
  { name: "Address", style: { minWidth: "180px" }, key: "address" },
  {
    name: "Emergency Contact",
    style: { minWidth: "180px" },
    key: "emergencyContact",
  },
  { name: "From", style: { minWidth: "180px" }, key: "startDate" },
  { name: "To", style: { minWidth: "180px" }, key: "endDate" },
  { name: "Experience", style: { minWidth: "180px" }, key: "experience" },
  {
    name: "Number of Guests",
    style: { minWidth: "180px" },
    key: "numOfGuests",
  },
  { name: "Budget", style: { minWidth: "180px" }, key: "budget" },
  { name: "Date Added", style: { minWidth: "180px" }, key: "createdAt" },
];
