const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
const BK_URL = process.env.REACT_APP_BASE_BK_URL;
const BK_URL_TWO = process.env.REACT_APP_BASE_BK_URL_TWO;
const BK_URL_THREE = process.env.REACT_APP_BASE_BK_URL_THREE;
const URLS = {
  LOGIN: `${BK_URL_TWO}/api/access/admin/login`,
  LOGOUT: `${BK_URL_TWO}/logout`,
  APPLICATIONS: `${BK_URL}/api/members/users`,
  EDIT_APPLICANT: `${BK_URL}/api/members/users/edit-applicant`,
  UPDATE: `${BK_URL}/api/members/users/status`,
  CAMPAIGNS: `${BK_URL_TWO}/api/access/events/find`,
  RSVP_EVENTS: `${BK_URL_TWO}/api/access/events/rsvp/find`,
  BUSINESSES: `${BK_URL_TWO}/api/access/businesses/find`,
  UPLOAD_FILES: `${BK_URL_TWO}/api/access/events/upload`,
  CREATE_BUSINESS: `${BK_URL_TWO}/api/access/businesses`,
  UPDATE_IMAGES: `${BK_URL_TWO}/api/access/events/images`,
  UPDATE_BUSINESS_IMAGES: `${BK_URL_TWO}/api/access/businesses/images`,
  TICKETS: `${BK_URL_TWO}/api/access/events`,
  REFRESH_TOKEN: "/api/members/users/auth/verify",
  DOWNLOAD_FILE: `${BK_URL}/api/members/users/download`,
  DOWNLOAD_CAMPAIGN: `${BK_URL_TWO}/api/access/events/download`,
  DOWNLOAD_BUSINESSES: `${BK_URL_TWO}/api/access/businesses/download`,
  DOWNLOAD_RSVP: `${BK_URL_TWO}/api/access/events/attendees-list/download`,
  GET_EVENT_TICKETS: `${BK_URL_TWO}/api/access/events/tickets`,
  UPDATE_BUSINESS: `${BK_URL_TWO}/api/access/businesses`,
  UPDATE_EVENT: `${BK_URL_TWO}/api/access/events`,
  BENEFITS: `${BK_URL_TWO}/api/access/benefits/all`,
  DOWNLOAD_BENEFITS: `${BK_URL_TWO}/api/access/benefits/download`,
  CREATE_BENEFITS: `${BK_URL_TWO}/api/access/benefits`,
  UPLOAD_RECAP: (id: string | number) =>
    `${BK_URL_TWO}/api/access/events/event-recap/${id}`,
  GET_RECAP: (id: string | number) =>
    `${BK_URL_TWO}/api/access/events/event-recap/${id}`,
  TOTALDOCS: (type: string) =>
    `${BK_URL_TWO}/api/access/events/totalDocs/${type}`,
  GET_INDIVIDUAL_BENEFIT: `${BK_URL_TWO}/api/access/benefits/individual`,
  UPDATE_BENEFIT: `${BK_URL_TWO}/api/access/benefits`,
  FLAG_USER: `${BK_URL}/api/members/users/update`,
  DELETE_TICKET: `${BK_URL_TWO}/api/access/events/tickets`,
  UPDATE_MEMBERSHIP_TYPE: `${BK_URL}/api/members/users/update`,
  GET_MEMBERSHIP_TYPE: `${BK_URL_TWO}/api/access/events/membership/type`,
  FORGOT_PASSWORD: `${BK_URL_TWO}/api/access/admin/forgot-password`,
  RESET_PASSWORD: `${BK_URL_TWO}/api/access/admin/reset-password`,
  UPDATE_EVENT_IMAGES: `${BK_URL_TWO}/api/access/events/images`,
  DOWNLOAD_PDF_FILE: `${BK_URL_TWO}/api/access/businesses/download-pdf`,
  GET_ADDITIONAL_GUEST: `${BK_URL_TWO}/api/access/events/rsvp/guest-list`,
  DELETE_EVENT: `${BK_URL_TWO}/api/access/events`,
  NOTIFICATIONS: `${BK_URL_TWO}/api/access/notifications/admin`,
  READ_NOTIFICATIONS: `${BK_URL_TWO}/api/access/notifications`,
  GET_ALL_EVENTS: `${BK_URL_TWO}/api/access/events/attendees-list`,
  GET_ALL_ATTENDEES: `${BK_URL_TWO}/api/access/events/rsvp/find`,
  GET_TICKETED_GUESTS: `${BK_URL_TWO}/api/access/events/purchased-tickets/find`,
  UPLOAD_BULK_DOCUMENTS: `${BK_URL_TWO}/api/access/businesses`,
  UPDATE_BUSINESS_WITH_DOCUMENTS: `${BK_URL_TWO}/api/access/businesses`,
  GET_BUSINESS_EDIT_DOCUMENTS: `${BK_URL_TWO}/api/access/businesses`,
  CHANGE_PASSWORD: `${BK_URL_TWO}/api/access/admin/change-password`,
  GET_USER_PROFILE: `${BK_URL_TWO}/api/access/admin`,
  CHANGE_PR0FILE_SETTINGS: `${BK_URL_TWO}/api/access/admin/profile`,
  GET_TIMEZONE: "https://maps.googleapis.com/maps/api/timezone/json",
  DOWNLOAD_BUSINESS_FILES: `${BK_URL_TWO}/api/access/businesses/download-business-document`,
  GET_CONTACTUS: `${BK_URL_TWO}/api/access/events/contactus`,
  GET_ENQUIRIES: `${BK_URL_TWO}/api/access/events/enquiry`,
  GET_INTAKES: `${BK_URL_TWO}/api/access/events/intake`,
  DOWNLOAD_ENQUIRY: `${BK_URL_TWO}/api/access/events/enquiry/downloadCsv`,
  DOWNLOAD_INTAKES: `${BK_URL_TWO}/api/access/events/intake/download`,
  DOWNLOAD_CONTACTUS: `${BK_URL_TWO}/api/access/events/contactus/downloadCsv`,
};
export default URLS;
